import { FC } from 'react'

import {
  integrationOptions,
  IntegrationType,
} from 'src/components/CustomerIntegrations/integrations'

import Button from '../Library/Button/Button'

import { ClientIntegration } from './CustomerIntegrationsCell'

interface IntegrationCardProps {
  integration: ClientIntegration
  handleIntegrationsClick: (integration: ClientIntegration) => void
}

interface IntegrationLogoProps {
  integrationType: IntegrationType
  size?: 'small'
}
export const IntegrationLogo: FC<IntegrationLogoProps> = ({
  integrationType,
  size = '',
}) => {
  const backgroundImageFileName = integrationOptions[integrationType]?.logo

  return (
    <div
      className={`rounded-lg bg-gray-200 bg-cover bg-center shadow-md ${
        size === 'small' ? 'h-6 w-6' : 'h-16 w-16 min-w-[64px]'
      }`}
      style={{ backgroundImage: 'url(/' + backgroundImageFileName + ')' }}
    ></div>
  )
}

export const StatusLabel: FC<{ active: boolean }> = ({ active }) => {
  if (active) {
    return (
      <p className="flex w-[min-content] items-center gap-2 rounded-full bg-green-100 px-3 py-1 text-xs text-green-600">
        <span className="block h-2 w-2 rounded-full bg-green-500"></span>
        <span>Active</span>
      </p>
    )
  } else {
    return (
      <p className="flex w-[min-content] items-center gap-2 rounded-full bg-amber-100 px-3 py-1 text-xs text-amber-600">
        <span className="block h-2 w-2 rounded-full bg-amber-500"></span>
        <span>Inactive</span>
      </p>
    )
  }
}

const IntegrationCard: FC<IntegrationCardProps> = ({
  integration,
  handleIntegrationsClick,
}) => {
  return (
    <div className="flex w-full flex-col justify-between gap-8 rounded-lg border border-gray-300 bg-white p-4 hover:shadow-lg">
      <div className="flex items-start justify-between gap-4">
        <div className="flex items-center gap-4">
          <IntegrationLogo integrationType={integration?.integrationType} />
          <p className="line-clamp-2 text-lg text-gray-800">
            {integration.integrationName}
          </p>
        </div>
        <StatusLabel active={integration?.active} />
      </div>
      <div className="flex items-center justify-between gap-4">
        <p className="text-sm text-gray-500">{integration.portfolioName}</p>
        <Button
          variant="outlined"
          fullWidth={false}
          onClick={() => handleIntegrationsClick(integration)}
        >
          Settings
        </Button>
      </div>
    </div>
  )
}

export default IntegrationCard
